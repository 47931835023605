// Defining a constant object to hold paths for various images
export const ImagesPath = {
  LOGO: require("./logo.png"),
  PROFILE: require("./profile.png"),
  PAGE_NOT_FOUND: require("./404.png"),
  NO_RATING: require("./no_rating.png"),
  LOGO_WHITE: require("./logo_white.png"),
  SERVER_DOWN: require("./server_down.png"),
  FULL_RATING: require("./full_rating.png"),
  HALF_RATING: require("./half_rating.png"),
  NO_INTERNET_CONNECTION: require("./no_internet.png"),
};
