import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { CognitoUser } from "amazon-cognito-identity-js";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import UserPool from "./UserPool";
import { Path } from "../../routes/Path";
import { ImagesPath } from "../../assets/images/ImagesPath";
import { SpinningLoader } from "../../components/common/Loader";
import { ConfirmPasswordSchema } from "../../assets/validation/Schema";
import ShowNotification from "../../components/common/ShowNotification";

// Define the FormData type for form values
type FormData = {
  code: string;
  password: string;
  newPassword: string;
};

const ConfirmPasswordScreen = () => {
  // React Router's navigate hook
  const navigate = useNavigate();
  // State to manage button loading state
  const [btnLoading, setBtnLoading] = useState(false);
  // State to toggle visibility of password and new password
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  // useEffect to set document title
  useEffect(() => {
    document.title = "FIDO";
  }, []);

  // React Hook Form setup
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<FormData>({
    resolver: yupResolver(ConfirmPasswordSchema),
  });

  // user email
  const userEmail = localStorage.getItem("userEmail");

  // Create a new CognitoUser instance
  const cognitoUser = new CognitoUser({
    Username: userEmail ?? "",
    Pool: UserPool,
  });

  // Handler function for confirming the new password
  const handleConfirmPassword = async (data: FormData) => {
    setBtnLoading(true);
    const { code, password } = data;

    // Confirm the new password using Cognito
    cognitoUser.confirmPassword(code, password, {
      onSuccess: () => {
        localStorage.removeItem("userEmail");
        // Show success notification and navigate to login page
        ShowNotification({
          message: "New Password is Set",
          type: "success",
        });
        navigate(Path.LOGIN);
        setBtnLoading(false);
      },
      onFailure: (err) => {
        // Show error notification if confirmation fails
        let errorMessage =
          typeof err === "string"
            ? err
            : err instanceof Error
            ? err.message
            : "";
        ShowNotification({
          message: errorMessage,
          type: "error",
        });
        setBtnLoading(false);
      },
    });
  };

  // Handler functions to toggle visibility of password and new password
  const handleViewPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleViewNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  // Function to handle resend otp
  const handleResendOtp = () => {
    cognitoUser.forgotPassword({
      onSuccess: () => {
        // Show success notification and navigate to confirm password screen
        ShowNotification({
          message: "Please Check the Email to get the Verification Code",
          type: "success",
        });
      },
      onFailure: (err) => {
        // Show error notification if forgot password process fails
        ShowNotification({
          message: err.message,
          type: "error",
        });
      },
    });
  };

  return (
    <div className="fixed h-full w-full left-0 top-0">
      <div className="w-full h-full flex flex-col items-center justify-center bg-neutral">
        <div className="bg-white rounded-xl shadow-md flex sm:p-8 p-4 sm:py-12 py-6 gap-4 flex-col w-11/12 md:w-2/4 lg:w-1/3 xxl:w-1/4 2xl:w-4/12">
          <img
            src={ImagesPath.LOGO}
            alt="FIDO Logo"
            className="mx-auto img-fluid"
          />
          <div>
            <p
              className="text-2xl font-semibold mb-6"
              data-testid="titleForgotPassword"
            >
              Confirm Password
            </p>
            <form onSubmit={handleSubmit(handleConfirmPassword)}>
              {/* Code input */}
              <div className="text-sm font-medium">
                <>
                  {" "}
                  <label
                    htmlFor="#code"
                    className="text-sm font-medium text-black"
                  >
                    Code
                  </label>
                  <input
                    aria-label="code"
                    id="code"
                    type="text"
                    placeholder="Enter Code"
                    {...register("code")}
                    className="block w-full rounded-md mt-2 px-2 py-2.5 text-black border border-gray-300 placeholder:text-slate-400 sm:text-sm focus:outline-none focus:border-primary focus:ring-primary"
                  />
                  {isSubmitted && errors.code && (
                    <p className="text-danger mt-1">{errors?.code?.message}</p>
                  )}
                </>
                {/* resend otp */}
                <div className="mt-1 flex justify-end text-primary">
                  <button type="button" onClick={handleResendOtp}>
                    Resend OTP
                  </button>
                </div>
              </div>

              {/* New Password input */}
              <div className="mb-4">
                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium mb-2"
                  >
                    New Password
                  </label>
                  <div className="relative">
                    <input
                      id="password"
                      aria-label="password"
                      data-testid="password"
                      type={showNewPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      {...register("newPassword")}
                      className="py-2.5 px-2 pr-11 block w-full border font-medium border-gray-300 rounded-md text-sm placeholder:text-slate-400 sm:text-sm focus:outline-none focus:border-primary focus:ring-primary"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center cursor-pointer z-20 pr-3">
                      {showNewPassword ? (
                        <EyeIcon
                          className="w-5 h-5 text-slate-400"
                          onClick={handleViewNewPassword}
                        />
                      ) : (
                        <EyeSlashIcon
                          className="w-5 h-5 text-slate-400"
                          onClick={handleViewNewPassword}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {isSubmitted && errors.newPassword && (
                  <p className="text-danger mt-1 font-medium text-sm">
                    {errors?.newPassword?.message}
                  </p>
                )}
              </div>
              {/* Confirm New Password input */}
              <div className="mb-4">
                <div>
                  <label
                    htmlFor="#new_password"
                    className="block text-sm font-medium mb-2"
                  >
                    Confirm New Password
                  </label>
                  <div className="relative">
                    <input
                      id="new_password"
                      aria-label="new_password"
                      data-testid="new_password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter confirm password"
                      {...register("password")}
                      className="py-2.5 px-2 pr-11 block w-full border font-medium border-gray-300 rounded-md text-sm placeholder:text-slate-400 sm:text-sm focus:outline-none focus:border-primary focus:ring-primary"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center cursor-pointer z-20 pr-3">
                      {showPassword ? (
                        <EyeIcon
                          className="w-5 h-5 text-slate-400"
                          onClick={handleViewPassword}
                        />
                      ) : (
                        <EyeSlashIcon
                          className="w-5 h-5 text-slate-400"
                          onClick={handleViewPassword}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {isSubmitted && errors.password && (
                  <p className="text-danger mt-1 font-medium text-sm">
                    {errors?.password?.message}
                  </p>
                )}
              </div>
              {/* Link to navigate back to Login */}
              <Link
                className="flex text-sm text-primary font-medium justify-end mb-4"
                to={Path.LOGIN}
              >
                Back to Login
              </Link>
              {/* Submit button */}
              <button
                type="submit"
                className="w-full rounded-md bg-primary px-3 py-2.5 text-base font-semibold leading-6 text-white shadow-sm"
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <div className="flex justify-center items-center py-1">
                    <SpinningLoader />
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPasswordScreen;
