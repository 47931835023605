import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../baseApi/CustomFetchBase";
import { ConfigUrl } from "../../../constants/URL";

export const TransactionsApi = createApi({
  reducerPath: "TransactionsApi", // Define the slice name for the reducer
  baseQuery: customFetchBase, // Use customFetchBase as the base for making queries
  endpoints: (builder) => ({
    // Define getTransactionsListApi endpoint for fetching transaction list data
    getTransactionsListApi: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.TRANSACTIONS?.LIST, // Construct URL for fetching transaction list data with query parameters
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetTransactionsListApiMutation } = TransactionsApi;
