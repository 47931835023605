import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../baseApi/CustomFetchBase";
import { ConfigUrl } from "../../../constants/URL";

export const DashboardApi = createApi({
  reducerPath: "DashboardApi", // Define the slice name for the reducer
  baseQuery: customFetchBase, // Use customFetchBase as the base for making queries
  endpoints: (builder) => ({
    // Define getDashoardcount endpoint for fetching dashboard counts data
    getDashoardCount: builder.mutation({
      query: () => ({
        url: ConfigUrl.DASHBOARD, // URL for fetching dashboard data
        method: "GET",
      }),
    }),
    // Define getDashoardchart endpoint for fetching dashboard counts data
    getDashboardChart: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.DASHBOARD,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetDashoardCountMutation, useGetDashboardChartMutation } =
  DashboardApi;
