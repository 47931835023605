// import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./assets/css/style.css";
import App from "./App";
import { store } from "./redux/Store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
);
