import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../baseApi/CustomFetchBase";
import { ConfigUrl } from "../../../constants/URL";

export const CustomersApi = createApi({
  reducerPath: "CustomersApi", // Define the slice name for the reducer
  baseQuery: customFetchBase, // Use customFetchBase as the base for making queries
  endpoints: (builder) => ({
    // Endpoint: customers list get api
    GetCustomersList: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.CUSTOMERS?.LIST, // URL for fetching customers list
        method: "GET",
        params,
      }),
    }),

    // Endpoint: customers list delete api
    CustomersListDeleteApi: builder.mutation({
      query: (body) => ({
        url: ConfigUrl.CUSTOMERS?.DELETE, // URL for deleting customer from list
        method: "PUT", // Specify the HTTP method as PUT
        body, // Include status and userId in the request body
      }),
    }),

    // Endpoint: customer detail get api
    CustomersDetailsGetApi: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.CUSTOMERS?.DETAILS, // URL for fetching customer details
        method: "GET",
        params,
      }),
    }),

    // Endpoint: customers payment api get
    CustomersPaymentApiGet: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.CUSTOMERS?.PAYMENTS, // URL for fetching customer payments
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetCustomersListMutation,
  useCustomersDetailsGetApiMutation,
  useCustomersPaymentApiGetMutation,
  useCustomersListDeleteApiMutation,
} = CustomersApi;
