import { createSlice } from "@reduxjs/toolkit";

// Define initial state for the user slice, fetching token from local storage if available
const initialState = {
  token: localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token") || "")
    : null,
};

// Create a user slice using createSlice from Redux Toolkit
export const userSlice = createSlice({
  initialState, // Set the initial state for the user slice
  name: "user", // Define the name of the slice as "user"
  reducers: {
    // Define a reducer function to save the AWS token to the state and local storage
    saveAwsToken: (state, action) => {
      state.token = action.payload; // Update the token in the state with the provided payload
      localStorage.setItem("token", action.payload); // Store the token in local storage
    },
  },
});

export default userSlice.reducer; // Export the reducer from the user slice

export const { saveAwsToken } = userSlice.actions; // Export the action creator for saving AWS token

// Define a selector function to retrieve the user state from the Redux store
export const currentUser = (state: any) => state.userState.user;
// export const currentUser = (state: any) => state.user;
