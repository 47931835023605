import { FC } from "react";
import { ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Defines the props required for the Notification component
type NotificationProps = {
  message: string; // Message to be displayed in the notification
  type: "success" | "error" | "warning" | "info"; // Type of notification: success, error, warning, or info
};

/**
 * Component to display notifications using react-toastify.
 * Shows notifications with a specific message and type (success, error, warning, or info).
 */

const ShowNotification: FC<NotificationProps> = ({ message, type }) => {
  // Configuration options for the toast notification
  const options: ToastOptions = {
    autoClose: 5000, // Time duration for the notification to automatically close (in milliseconds)
  };

  /**
   * Function to display the toast notification with the specified message and type.
   * Uses the react-toastify `toast` function to show notifications.
   */
  const showToast = () => {
    toast[type](message, options);
  };

  // Renders the notification using the showToast function
  return <>{showToast()}</>;
};

export default ShowNotification;
