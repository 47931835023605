import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../baseApi/CustomFetchBase";
import { ConfigUrl } from "../../../constants/URL";

export const SettingsApi = createApi({
  reducerPath: "SettingsApi", // Define the slice name for the reducer
  baseQuery: customFetchBase, // Use customFetchBase as the base for making queries
  endpoints: (builder) => ({
    // Define settingsGetApi endpoint for fetching settings
    settingsGetApi: builder.query({
      query: () => ({
        url: ConfigUrl?.SETTINGS?.RADIUS, // URL for fetching settings data
      }),
    }),

    // Define settingsPutApi endpoint for updating settings
    settingsPutApi: builder.query({
      query: ({ id = "id", type = "type", value = "value" }) => ({
        url: ConfigUrl?.SETTINGS?.RADIUS, // URL for updating settings data
        method: "PUT", // Specify the HTTP method as PUT
        body: { id, type, value }, // Include id, type, and value in the request body
      }),
    }),
  }),
});

export const { useLazySettingsGetApiQuery, useLazySettingsPutApiQuery } =
  SettingsApi;
