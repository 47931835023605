import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/solid";
import {
  UserIcon,
  ChevronDownIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import "../../assets/css/input.css";
import { Image } from "./Image";
import Notification from "./Notification";
import ShowNotification from "./ShowNotification";
import { Path } from "../../routes/Path";
import { HeaderProps } from "../../constants/Interfaces";
import UserPool from "../../pages/login/UserPool";
import { ImagesPath } from "../../assets/images/ImagesPath";

/**
 * Header component contains the header section of the application,
 * including navigation, user profile, and logout functionality
 * @param {HeaderProps} props - Properties passed to the Header component
 * @returns {JSX.Element} - Returns the JSX for the Header component
 */

const Header: FC<HeaderProps> = ({ onClickMobileNav }) => {
  const navigate = useNavigate();

  // Fetch user details from local storage
  const userData = JSON.parse(localStorage.getItem("userDetails") ?? "null");

  // Logout functionality
  const handleLogout = () => {
    const user = UserPool.getCurrentUser();
    user?.signOut();
    localStorage.clear();
    ShowNotification({ message: "Logged Out Successfully", type: "success" });
    navigate(Path.LOGIN);
  };

  // Navigation to profile page
  const handleProfile = () => navigate(Path.PROFILE);

  return (
    <header className="sm:px-8 px-4 py-4 bg-white flex justify-between xl:justify-end items-center border-bottom">
      {/* Toggle button for mobile navigation */}
      <button
        className="block xl:hidden"
        onClick={() => {
          onClickMobileNav();
        }}
      >
        <Bars3BottomLeftIcon className="w-6 h-6 text-black" />
      </button>
      <div className="flex gap-4 items-center">
        {/* Notification component */}
        <Notification />
        <div className="relative">
          {/* User profile menu */}
          <Menu>
            <div className="flex gap-2 items-center">
              <span className="h-8 w-8 rounded-full overflow-hidden flex justify-center items-center gap-2">
                {userData?.data?.profilePhotoFileName ? (
                  <Image photo={userData?.data?.profilePhotoFileName} />
                ) : (
                  <img
                    src={ImagesPath.PROFILE}
                    alt="adminProfile"
                    className="rounded-full w-full h-full"
                  />
                )}
              </span>
              {/* User name and dropdown icon */}
              <p>
                {userData?.data?.firstName && userData?.data?.lastName
                  ? `${userData?.data?.firstName} ${userData?.data?.lastName}`
                  : userData?.data?.firstName}
              </p>
            <Menu.Button className="z-0 align-middle">
              <ChevronDownIcon
                className="w-4 h-4 text-black"
                data-testid="headerChevronDownIcon"
              />
            </Menu.Button>
            </div>
            {/* Dropdown menu items */}
            <Menu.Items className="absolute z-10 right-0 origin-top-right rounded-lg border border-black/10 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100 flex flex-col top-10">
              <Menu.Item>
                {/* Profile menu item */}
                <div
                  onClick={handleProfile}
                  className="px-5 py-3 items-center flex gap-2 text-black cursor-pointer"
                >
                  <UserIcon className="w-6 h-6" />
                  Profile
                </div>
              </Menu.Item>
              <Menu.Item>
                {/* Logout menu item */}
                <div
                  onClick={handleLogout}
                  className="px-5 py-3 items-center flex gap-2 text-black cursor-pointer"
                >
                  <ArrowRightOnRectangleIcon className="w-6 h-6" />
                  Logout
                </div>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>
    </header>
  );
};

export default Header;
