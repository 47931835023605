import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "@smastrom/react-rating/style.css";
import { Path } from "./routes/Path";
import RouteAccess from "./routes/RouteAccess";
import PrivateRoute from "./routes/PrivateRoute";
import Login from "./pages/login/Login";
import UserPool from "./pages/login/UserPool";
import Layout from "./components/common/Layout";
import PageNotFound from "./components/common/PageNotFound";
import SplashScreen from "./components/common/SplashScreen";
import NetworkStatus from "./components/common/NetworkStatus";
import ServerDownPage from "./components/common/ServerDownPage";
import ForgotPasswordScreen from "./pages/login/ForgotPasswordScreen";
import ConfirmPasswordScreen from "./pages/login/ConfirmPasswordScreen";
import NoInternetConnectionPage from "./components/common/NoInternetConnectionPage";

const App = () => {
  const isOnline = NetworkStatus();
  const routesPath = [...RouteAccess()];

  useEffect(() => {
    setTimeout(() => {
      localStorage.clear();
      window.location.replace(Path.LOGIN);
    }, 14400000);
    window.onbeforeunload = () => {};
  }, []);

  const isAuth = () => {
    let user = UserPool.getCurrentUser();
    if (user) {
      return true;
    } else return false;
  };

  const Wrapper = () => {
    return (
      <div className="wrapper">
        {isOnline ? <Outlet /> : <NoInternetConnectionPage />}
        <ToastContainer
          theme="light"
          draggable={false}
          transition={Slide}
          closeOnClick={false}
          pauseOnHover={false}
        />
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        <Route element={<Wrapper />}>
          <Route path={Path.LOGIN} element={<Login />} />
          <Route element={<PrivateRoute isAuth={isAuth} />}>
            {routesPath &&
              routesPath.length &&
              routesPath.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={<Layout>{route.element}</Layout>}
                  />
                );
              })}
          </Route>
          <Route path={Path.SPLASH} element={<SplashScreen />} />
          <Route
            path={Path.FORGOT_PASSWORD}
            element={<ForgotPasswordScreen />}
          />
          <Route
            path={Path.CONFIRM_PASSWORD}
            element={<ConfirmPasswordScreen />}
          />
        </Route>
        <Route element={<ServerDownPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
