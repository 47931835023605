export const domainConfigurations = [
  {
    // Dev environment configuration
    domain: process.env.REACT_APP_DEV_DOMAIN,
    api_url: process.env.REACT_APP_DEV_URl,
    socket_url: process.env.REACT_APP_DEV_SOCKET_URL,
    UserPoolId: process.env.REACT_APP_DEV_USER_POOL_ID,
    ClientId: process.env.REACT_APP_DEV_CLIENT_ID,
  },
  {
    // QA environment configuration
    domain: process.env.REACT_APP_QA_DOMAIN,
    api_url: process.env.REACT_APP_QA_URl,
    socket_url: process.env.REACT_APP_QA_SOCKET_URL,
    UserPoolId: process.env.REACT_APP_QA_USER_POOL_ID,
    ClientId: process.env.REACT_APP_QA_CLIENT_ID,
  },
  {
    // STAGE environment configuration
    domain: process.env.REACT_APP_STAGE_DOMAIN,
    api_url: process.env.REACT_APP_STAGE_URl,
    socket_url: process.env.REACT_APP_STAGE_SOCKET_URL,
    UserPoolId: process.env.REACT_APP_STAGE_USER_POOL_ID,
    ClientId: process.env.REACT_APP_STAGE_CLIENT_ID,
  },
  {
    // PROD environment configuration
    domain: process.env.REACT_APP_PROD_DOMAIN,
    api_url: process.env.REACT_APP_PROD_URl,
    socket_url: process.env.REACT_APP_PROD_SOCKET_URL,
    UserPoolId: process.env.REACT_APP_PROD_USER_POOL_ID,
    ClientId: process.env.REACT_APP_PROD_CLIENT_ID,
  },
  {
    // Default configuration if domain doesn't match
    domain: "default",
    api_url: process.env.REACT_APP_DEV_URl,
    socket_url: process.env.REACT_APP_DEV_SOCKET_URL,
    UserPoolId: process.env.REACT_APP_DEV_USER_POOL_ID,
    ClientId: process.env.REACT_APP_DEV_CLIENT_ID,
  },
];

/**
 * Function to fetch the domain configuration based on the window's location hostname
 * @returns {object} - Domain configuration object based on the hostname or default if not found
 */
export const fetchDomainConfiguration = () => {
  const domain = window.location.hostname; // Retrieve the current hostname from the window
  let domainConfigs; // Variable to store domain configurations

  // If domainConfigs is not set, assign the default domainConfigurations
  if (!domainConfigs) {
    domainConfigs = domainConfigurations;
  }

  // Find a domain configuration that matches the current hostname
  let domainConfig = domainConfigs.find(
    (domainConfiguration) => domainConfiguration.domain === domain
  );

  // If no configuration found for the current hostname, use the default configuration
  if (!domainConfig) {
    domainConfig = domainConfigurations.find(
      (domainConfiguration) => domainConfiguration.domain === "default"
    );
  }
  return domainConfig; // Return the found domain configuration or the default one
};
