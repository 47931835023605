// API endpoints configuration object
export const ConfigUrl = {
  DASHBOARD: "dashboard",
  IMAGES: "configuration-token",
  JOBSLISTDETAILPAGE: "job-list",
  NOTIFICATION: "notification",
  GUARDS: {
    CHAT: "chat",
    LIST: "admin-list",
    FILTER: "job-interest",
    DETAILS: "guard-details",
    APPROVEREJECTDELETE: "approve-guard",
  },
  CUSTOMERS: {
    LIST: "admin-list",
    DELETE: "approve-guard",
    DETAILS: "guard-details",
    PAYMENTS: "payment-accounts",
  },
  JOBS: {
    CHAT: "chat",
    DELETE: "job",
    LIST: "admin-list",
    DETAILS: "guard-details",
  },
  TRANSACTIONS: {
    LIST: "admin-list",
  },
  RATINGS_REVIEWS: {
    LIST: "admin-list",
  },
  SETTINGS: {
    RADIUS: "radius",
  },
  PROFILE: {
    PROFILE: "profile",
  },
};
