import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../baseApi/CustomFetchBase";
import { ConfigUrl } from "../../../constants/URL";

export const JobsApi = createApi({
  reducerPath: "JobsApi", // Define the slice name for the reducer
  baseQuery: customFetchBase, // Use customFetchBase as the base for making queries
  endpoints: (builder) => ({
    // Define jobsListApiGet endpoint for fetching jobs list
    jobsListApiGet: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.JOBS?.LIST, // Construct URL for fetching jobs list with query parameters
        method: "GET",
        params,
      }),
    }),

    // Define JobsListDeleteApi endpoint for deleting jobs
    JobsListDeleteApi: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.JOBS?.DELETE, // Construct URL for deleting jobs with jobId and status
        method: "DELETE", // Specify the HTTP method as DELETE
        params,
      }),
    }),

    // Define JobDetailsGetApi endpoint for fetching job details
    JobDetailsGetApi: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.JOBS?.DETAILS, // Construct URL for fetching job details with id and type
        method: "GET",
        params,
      }),
    }),

    // Define Job Details checklist and biometrics Get Api endpoint
    ChecklistBiometrics: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.JOBS?.DETAILS, // Construct URL for fetching job details checklist and biometrics
        method: "GET",
        params,
      }),
    }),

    // Define JobsChatHistory endpoint for fetching job chat history
    JobsChatHistory: builder.query({
      query: ({
        total = "total",
        jobId = "jobId",
        pageNo = "currentPage",
      }) => ({
        url: `${ConfigUrl.JOBS?.CHAT}?jobId=${jobId}&page=${pageNo}&pageSize=${total}`, // Construct URL for fetching job chat history with jobId and pagination parameters
      }),
    }),
  }),
});

export const {
  useJobsListApiGetMutation,
  useLazyJobsChatHistoryQuery,
  useJobDetailsGetApiMutation,
  useJobsListDeleteApiMutation,
  useChecklistBiometricsMutation,
} = JobsApi;
