import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./baseApi/CustomFetchBase";
import { ConfigUrl } from "../../constants/URL";

// Images api is common for all the pages
export const ImagesApi = createApi({
  reducerPath: "ImagesApi", // Define the slice name for the reducer
  baseQuery: customFetchBase, // Use customFetchBase as the base for making queries
  endpoints: (builder) => ({
    // Define Img Api endpoint for fetching images
    ImgApiGet: builder.query({
      query: ({ type = "type", folder = "folder", fileName = "fileName" }) => ({
        url: `${ConfigUrl.IMAGES}?type=${type}&folder=${folder}&fileName=${fileName}`, // URL for fetching images
      }),
    }),
  }),
});

export const { useImgApiGetQuery } = ImagesApi;
