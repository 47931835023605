import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
import { yupResolver } from "@hookform/resolvers/yup";
import UserPool from "./UserPool";
import { Path } from "../../routes/Path";
import { fetchDomainConfiguration } from "../../assets/DomainConfiguration";
import { ImagesPath } from "../../assets/images/ImagesPath";
import { SpinningLoader } from "../../components/common/Loader";
import { ForgotPasswordSchema } from "../../assets/validation/Schema";
import ShowNotification from "../../components/common/ShowNotification";

// Define the FormData type for form values
type FormData = {
  email: string;
};

const ForgotPasswordScreen = () => {
  // React Router's navigate hook
  const navigate = useNavigate();
  // State to manage button loading state
  const [btnLoading, setBtnLoading] = useState(false);
  // Fetch domain configuration data
  const fetchDomains = fetchDomainConfiguration();
  const baseUrl = fetchDomains?.api_url;

  // useEffect to set document title
  useEffect(() => {
    document.title = "FIDO";
  }, []);

  // React Hook Form setup
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<FormData>({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  // Handler function for initiating the forgot password process
  const handleForgotPassword = async (data: FormData) => {
    setBtnLoading(true);
    const { email } = data;
    const type = "SUPERADMIN";
    // Fetch verification code via API
    fetch(`${baseUrl}/verify-otp?email=${email}&type=${type}`)
      .then((response) => response.json())
      .then((data) => {
        // Check the response code and handle accordingly
        if (data?.code === 0) {
          // Initiate forgot password process using Cognito
          const cognitoUser = new CognitoUser({
            Username: email,
            Pool: UserPool,
          });
          cognitoUser.forgotPassword({
            onSuccess: () => {
              localStorage.setItem("userEmail", email);
              // Show success notification and navigate to confirm password screen
              ShowNotification({
                message: "Please Check the Email to get the Verification Code",
                type: "success",
              });
              navigate(Path.CONFIRM_PASSWORD);
              setBtnLoading(false);
            },
            onFailure: (err) => {
              // Show error notification if forgot password process fails
              ShowNotification({
                message: err.message,
                type: "error",
              });
              setBtnLoading(false);
            },
          });
        } else if (data?.code === 1) {
          // Show warning notification if verification code request fails
          ShowNotification({
            message: data?.message,
            type: "warning",
          });
          setBtnLoading(false);
        } else {
          // Show error notification for unhandled response code
          ShowNotification({
            message: "Unhandled response code",
            type: "error",
          });
          setBtnLoading(false);
        }
      })
      .catch((error) => {
        // Show error notification if user does not exist
        ShowNotification({
          message: "User does not exist",
          type: "error",
        });
        setBtnLoading(false);
      });
  };

  return (
    <div className="fixed h-full w-full left-0 top-0">
      <div className="w-full h-full flex flex-col items-center justify-center bg-neutral">
        <div className="bg-white rounded-xl shadow-md flex sm:p-8 p-4 sm:py-12 py-6 gap-4 flex-col w-11/12 md:w-2/4 lg:w-1/3 xxl:w-1/4 2xl:w-4/12">
          <img
            src={ImagesPath.LOGO}
            alt="FIDO Logo"
            className="mx-auto img-fluid"
          />
          <div>
            <p
              className="text-2xl font-semibold mb-6"
              data-testid="titleForgotPassword"
            >
              Forgot Password
            </p>
            <form onSubmit={handleSubmit(handleForgotPassword)}>
              {/* Email input */}
              <div className="text-sm font-medium mb-4">
                <label className="text-sm font-medium text-black">Email</label>
                <input
                  aria-label="email"
                  id="email"
                  type="text"
                  placeholder="Enter email address"
                  {...register("email")}
                  className="block w-full rounded-md mt-2 px-2 py-2.5 text-black border border-gray-300 placeholder:text-slate-400 sm:text-sm focus:outline-none focus:border-primary focus:ring-primary"
                />
                {isSubmitted && errors.email && (
                  <p className="text-danger mt-1">{errors?.email?.message}</p>
                )}
              </div>
              {/* Link to navigate back to Login */}
              <Link
                className="flex text-sm font-medium justify-end mb-4 text-primary"
                to={Path.LOGIN}
              >
                Back to Login
              </Link>
              {/* Submit button */}
              <button
                type="submit"
                className="w-full rounded-md bg-primary px-3 py-2.5 text-base font-semibold leading-6 text-white shadow-sm"
                disabled={btnLoading}
              >
                {btnLoading ? (
                  <div className="flex justify-center items-center">
                    <SpinningLoader />
                  </div>
                ) : (
                  "Click here"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordScreen;
