import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../baseApi/CustomFetchBase";
import { ConfigUrl } from "../../../constants/URL";

export const GuardsApi = createApi({
  reducerPath: "GuardsApi", // Define the slice name for the reducer
  baseQuery: customFetchBase, // Use customFetchBase as the base for making queries
  endpoints: (builder) => ({
    // Define GuardsListGetApi endpoint for fetching guards list (individual & team)
    GuardsListGetApi: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.GUARDS?.LIST, // Construct URL for fetching guards list with query parameters
        method: "GET",
        params,
      }),
    }),

    // Define GuardslistDeleteApi endpoint for deleting guards
    GuardslistDeleteApi: builder.mutation({
      query: (body) => ({
        url: ConfigUrl.GUARDS?.APPROVEREJECTDELETE, // URL for deleting guards
        method: "PUT", // Specify the HTTP method as PUT
        body, // Include status and userId in the request body
      }),
    }),

    // Define GuardslistAppOrRejPostApi endpoint for approving or rejecting guards
    GuardslistAppOrRejPostApi: builder.mutation({
      query: (body) => ({
        url: ConfigUrl.GUARDS?.APPROVEREJECTDELETE, // URL for approving or rejecting guards
        method: "POST", // Specify the HTTP method as POST
        body, // Include status and userId in the request body
      }),
    }),

    // Define GuardsFilterListGetApi endpoint for fetching guards filter list
    GuardsFilterListGetApi: builder.query({
      query: () => ({
        url: ConfigUrl.GUARDS?.FILTER, // URL for fetching guards filter list
      }),
    }),

    // Define GuardDetailsGetApi endpoint for fetching details of a guard
    GuardDetailsGetApi: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.GUARDS?.DETAILS, // URL for fetching guard details
        method: "GET",
        params,
      }),
    }),

    // Define GuardsTeamListGetApi endpoint for fetching a list of individual guards in a team
    GuardsTeamListGetApi: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.GUARDS?.LIST, // URL for fetching individual guards in a team
        method: "GET",
        params,
      }),
    }),

    // Define GuardReviewsGetApi endpoint for fetching reviews for a guard
    GuardReviewsGetApi: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.GUARDS?.DETAILS, // URL for fetching reviews for a guard
        method: "GET",
        params,
      }),
    }),

    // Define SocketCreateRoomIdPostApi endpoint for creating room ID for chat
    SocketCreateRoomIdPostApi: builder.mutation({
      query: ({ userId = "userId" }) => ({
        url: ConfigUrl.GUARDS?.CHAT, // URL for creating room ID for chat
        method: "POST", // Specify the HTTP method as POST
        body: { userId }, // Include chatType and userId in the request body
      }),
    }),

    // Define SocketPrevConversationsGetApi endpoint for fetching previous conversations for a chat
    SocketPrevConversationsGetApi: builder.query({
      query: ({
        total = "total",
        chatId = "chatId",
        pageNo = "currentPage",
      }) => ({
        url: `${ConfigUrl.GUARDS?.CHAT}?chatId=${chatId}&page=${pageNo}&pageSize=${total}`, // URL for fetching previous conversations for a chat
      }),
    }),
  }),
});

export const {
  useGuardsListGetApiMutation,
  useGuardDetailsGetApiMutation,
  useGuardReviewsGetApiMutation,
  useGuardslistDeleteApiMutation,
  useGuardsFilterListGetApiQuery,
  useGuardsTeamListGetApiMutation,
  useGuardslistAppOrRejPostApiMutation,
  useSocketCreateRoomIdPostApiMutation,
  useLazySocketPrevConversationsGetApiQuery,
} = GuardsApi;
