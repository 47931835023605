import * as yup from "yup";
import { Constants } from "../../constants/Constants";

// Login schema
export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .required(Constants?.IsEmailEmpty) // Email field required validation
    .max(100, Constants?.IsEmailLength) // Maximum length validation for email
    .email(Constants?.IsEmailInvalid) // Email format validation
    .test("valid-domain", "Invalid email domain", (value) => {
      if (!value) return false; // Empty value considered invalid
      const domainRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/; // Regex for validating domain
      return domainRegex.test(value);
    }),
  password: yup.string().required(Constants?.IsPasswordEmpty), // Password field required validation
});

// Forgot password schema
export const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required(Constants?.IsEmailEmpty) // Email field required validation
    .max(100, Constants?.IsEmailLength) // Maximum length validation for email
    .email(Constants?.IsEmailInvalid) // Email format validation
    .test("valid-domain", "Invalid email domain", (value) => {
      if (!value) return false; // Empty value considered invalid
      const domainRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/; // Regex for validating domain
      return domainRegex.test(value);
    }),
});

// Confirm password schema
export const ConfirmPasswordSchema = yup.object().shape({
  code: yup.string().required(Constants?.IsCodeEmpty), // Code field required validation
  newPassword: yup
    .string()
    .required(Constants?.isNewPasswordEmpty) // New password field required validation
    .min(8, Constants?.isPasswordLength), // Minimum length validation for new password
  password: yup
    .string()
    .required(Constants?.isConfirmPasswordEmpty) // Password field required validation
    .oneOf([yup.ref("newPassword")], Constants?.isPasswordMatch), // Password match validation
});
