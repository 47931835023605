import { ImagesPath } from "../../assets/images/ImagesPath";

/**
 * NoInternetConnectionPage component displays a page indicating no internet connection.
 * It provides an option to refresh the page.
 */

const NoInternetConnectionPage = () => {
  /**
   * Function to handle the page refresh.
   * Reloads the window to refresh the page.
   */
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="fixed h-full w-full left-0 top-0">
      <div className="w-full h-full flex items-center justify-center">
        <div className="flex h-screen flex-col">
          <div className="m-auto text-center">
            <img
              src={ImagesPath.NO_INTERNET_CONNECTION}
              alt=""
              className="m-auto mb-8"
            />
            <p className="text-3xl font-medium mb-4">No Internet Connection</p>
            <p>
              Yours Connection appers to be off-line.<br></br>Try to refresh the
              page
            </p>
            <button
              className="border border-primary text-primary py-1.5 px-6 rounded-full mt-6 font-weight-semibold"
              onClick={() => handleRefresh()}
            >
              Refresh
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoInternetConnectionPage;
