import { Suspense, lazy } from "react";
import { Path } from "./Path";
import { DotsLoader } from "../components/common/Loader";

// Function to wrap lazy-loaded components with Suspense for lazy loading
const LazyLoading = (Component: any) => (props: any) => {
  return (
    <Suspense
      fallback={
        <div className="loader-css flex items-center justify-center h-full w-full text-center">
          <DotsLoader /> {/* Loader component displayed while lazy loading */}
        </div>
      }
    >
      <Component {...props} /> {/* Render the lazy-loaded component */}
    </Suspense>
  );
};

// Lazy-loaded components wrapped with LazyLoading HOC
const JobsList = LazyLoading(lazy(() => import("../pages/jobs/JobsList")));
const Profile = LazyLoading(lazy(() => import("../pages/profile/Profile")));
const Settings = LazyLoading(lazy(() => import("../pages/settings/Settings")));
const GuardChat = LazyLoading(
  lazy(() => import("../pages/guards/guardChatting/GuardChat"))
);
const JobDetails = LazyLoading(
  lazy(() => import("../pages/jobs/jobDetails/JobDetails"))
);
const GuardsList = LazyLoading(
  lazy(() => import("../pages/guards/guardsList/GuardsList"))
);
const Dashboard = LazyLoading(
  lazy(() => import("../pages/dashboard/Dashboard"))
);
const GuardReviews = LazyLoading(
  lazy(() => import("../pages/guards/GuardReviews"))
);
const GuardDetails = LazyLoading(
  lazy(() => import("../pages/guards/guardDetails/GuardDetails"))
);
const GuardJobsList = LazyLoading(
  lazy(() => import("../pages/guards/GuardJobsList"))
);
const GuardsTeamsList = LazyLoading(
  lazy(() => import("../pages/guards/guardsList/GuardsTeamsList"))
);
const CustomersList = LazyLoading(
  lazy(() => import("../pages/customers/CustomersList"))
);
const CustomerDetails = LazyLoading(
  lazy(() => import("../pages/customers/customerDetails/CustomerDetails"))
);
const CustomerJobsList = LazyLoading(
  lazy(() => import("../pages/customers/CustomerJobsList"))
);
const TransactionsList = LazyLoading(
  lazy(() => import("../pages/transaction/TransactionsList"))
);
const TransactionDetails = LazyLoading(
  lazy(() => import("../pages/transaction/TransactionDetails"))
);
const RatingsAndReviews = LazyLoading(
  lazy(() => import("../pages/ratingsandreviews/RatingsAndReviews"))
);

// Define routes with paths and corresponding lazy-loaded elements
const RouteAccess = () => {
  return [
    { path: Path.PROFILE, element: <Profile /> },
    { path: Path.SETTINGS, element: <Settings /> },
    { path: Path.JOBS_LIST, element: <JobsList /> },
    { path: Path.DASHBOARD, element: <Dashboard /> },
    { path: Path.GUARDS_LIST, element: <GuardsList /> },
    { path: Path.JOB_DETAILS, element: <JobDetails /> },
    { path: Path.GUARDS_CHATTING, element: <GuardChat /> },
    { path: Path.GUARD_DETAILS, element: <GuardDetails /> },
    { path: Path.GUARD_REVIEWS, element: <GuardReviews /> },
    { path: Path.GUARD_JOBSLIST, element: <GuardJobsList /> },
    { path: Path.CUSTOMERS_LIST, element: <CustomersList /> },
    { path: Path.GUARD_TEAM_DETAILS, element: <GuardDetails /> },
    { path: Path.GUARD_TEAM_LIST, element: <GuardsTeamsList /> },
    { path: Path.CUSTOMER_DETAILS, element: <CustomerDetails /> },
    { path: Path.GUARD_TEAM_JOBSLIST, element: <GuardJobsList /> },
    { path: Path.TRANSACTIONS_LIST, element: <TransactionsList /> },
    { path: Path.CUSTOMER_JOBSLIST, element: <CustomerJobsList /> },
    { path: Path.RATINGSANDREVIEWS, element: <RatingsAndReviews /> },
    { path: Path.TRANSACTION_DETAILS, element: <TransactionDetails /> },
  ];
};
export default RouteAccess;
