import { CognitoUserPool } from "amazon-cognito-identity-js";
import { fetchDomainConfiguration } from "../../assets/DomainConfiguration";

// Fetch domain configuration including UserPoolId and ClientId
const fetchDomains = fetchDomainConfiguration();
const userPoolIds = fetchDomains?.UserPoolId ?? ""; // Retrieve UserPoolId from fetched configuration
const clientIds = fetchDomains?.ClientId ?? ""; // Retrieve ClientId from fetched configuration

// Configure user pool data using the retrieved UserPoolId and ClientId
const poolData = {
  UserPoolId: userPoolIds,
  ClientId: clientIds,
};

// Create an instance of CognitoUserPool with the configured pool data
const UserPool = new CognitoUserPool(poolData);

export default UserPool;
