import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Menu } from "@headlessui/react";
import { BellIcon } from "@heroicons/react/24/outline";
import { DotsLoader } from "./Loader";
import { Path } from "../../routes/Path";
import { Constants } from "../../constants/Constants";
import {
  useLazyMarkAsReadPatchApiQuery,
  useLazyNotificationGetApiQuery,
  useLazyMarkAllAsReadPatchApiQuery,
} from "../../redux/service/notificationApi";

const Notification = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const notyContainerRef = useRef<HTMLDivElement>(null);
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [markAsReadApi] = useLazyMarkAsReadPatchApiQuery();
  const [markAllAsReadApi, { isFetching: isMarkAllLoading }] =
    useLazyMarkAllAsReadPatchApiQuery();
  const [
    getNotifications,
    {
      isFetching: isNotificationLoading,
      isSuccess: isNotificationSuccess,
      data: {
        data: {
          results: notificationItems = [],
          pages = 0,
          unreadCount = 0,
        } = {},
      } = {},
    },
  ] = useLazyNotificationGetApiQuery();

  useEffect(() => {
    if (isNotificationSuccess) {
      setNotificationData((prev: any[]) => {
        const uniqueNotifications = notificationItems.filter(
          (newItem: any) =>
            !prev.some((existingItem) => existingItem.id === newItem.id)
        );
        // Concatenate the unique notifications with the previous state
        return [...prev, ...uniqueNotifications];
      });
    }
  }, [isNotificationSuccess, notificationItems]);

  useEffect(() => {
    // Function to fetch notifications
    const fetchNotifications = () => {
      getNotifications({
        total: Constants?.paginationCount,
        pageNo: currentPage,
      });
    };

    // Initial call to fetch notifications
    fetchNotifications();

    // Setup interval to fetch notifications every 5 minutes
    const notificationsData = setInterval(() => {
      fetchNotifications();
    }, 5 * 60 * 1000);

    // Cleanup on unmount or change
    return () => {
      clearInterval(notificationsData);
    };
    // eslint-disable-next-line
  }, [currentPage]); // Add any dependencies that should trigger a re-run of this effect

  // mark all as read
  const markAllAsRead = async () => {
    markAllAsReadApi("")
      .then((res: any) => {
        if (res && res.data) {
          // Clear notificationData before fetching notifications again
          setNotificationData([]);
          setCurrentPage(1);
          return updateNotificationsAfterMarkAsRead();
        }
      })
      .catch((error) => {
        console.log("Error marking all as read:", error);
      });
  };

  // const [notifyPageId, setNotifyPageId] = useState(0);

  // mark as read
  const markAsRead = async (id: number, toPage: string, toPageId: number) => {
    // Always navigate, regardless of whether notifyPageId is different or not
    if (toPage === "GUARDS_LIST") {
      navigate(Path.GUARDS_LIST);
    } else if (toPage === "CUSTOMER_LIST") {
      navigate(Path.CUSTOMERS_LIST);
    } else if (toPage === "JOB_DETAIL") {
      navigate(Path.JOB_DETAILS, {
        state: {
          id: toPageId,
          type: "adminjob",
        },
      });
    } else if (toPage === "RATINGS_REVIEWS") {
      navigate(Path.RATINGSANDREVIEWS);
    } else {
      return false;
    }

    // if (notifyPageId !== toPageId) {
    const res = await markAsReadApi({
      notificationId: id,
    });
    if (res && res?.data) {
      // Clear notificationData before fetching notifications again
      setNotificationData([]);
      setCurrentPage(1);
      await updateNotificationsAfterMarkAsRead();
      // setNotifyPageId(toPageId);
    }
    // }
  };

  const updateNotificationsAfterMarkAsRead = async () => {
    try {
      // Fetch the updated notifications after marking as read
      const updatedNotifications = await getNotifications({
        total: Constants?.paginationCount,
        pageNo: 1,
      });

      if (updatedNotifications.isSuccess && updatedNotifications.data) {
        const { data: { results: updatedNotificationItems = [] } = {} } =
          updatedNotifications.data;
        setNotificationData((prevNotifications) => {
          // Logic to update the notifications array with updated data
          const updatedData = [...prevNotifications];
          updatedNotificationItems.forEach((updatedItem: any) => {
            const index = updatedData.findIndex(
              (item) => item.id === updatedItem.id
            );
            if (index !== -1) {
              updatedData[index] = updatedItem;
            }
          });
          return updatedData;
        });
      }
    } catch (error) {
      console.log("Error updating notifications after marking as read:", error);
    }
  };

  const handleScroll = (event: any) => {
    const e = event.nativeEvent;
    if (
      !isNotificationLoading && // Check if a request is not already in progress
      currentPage < pages &&
      e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
      getNotifications({
        total: Constants?.paginationCount,
        pageNo: currentPage + 1,
      });
    }
  };

  const renderNotificationContent = () => {
    const messagesList: { [key: string]: any[] } = {};
    notificationData.forEach((item: any) => {
      const groupTitle =
        moment(item.createdOn).format("L") === moment().format("L")
          ? "Today"
          : moment(item.createdOn).format("L") ===
            moment().subtract(1, "day").format("L")
          ? "Yesterday"
          : moment(item.createdOn).format("dddd, MMMM Do"); // Format for older dates

      if (!messagesList[groupTitle]) {
        messagesList[groupTitle] = [];
      }
      messagesList[groupTitle].push(item);
    });

    return (
      <div className="div">
        {Object.entries(messagesList).length > 0 ? (
          Object.entries(messagesList).map(
            ([groupTitle, groupItems], index) => (
              <div className="text-black text-small mr-4" key={index}>
                <span className="text-secondary text-sm flex my-3">
                  {groupTitle}
                </span>
                {groupItems?.length > 0 &&
                  groupItems.map((groupItem: any, idx: number) => (
                    <div
                      className="gap-2 rounded-lg shadow p-2 cursor-pointer transition hover:bg-gray-300 bg-gray-100 mb-4"
                      key={idx}
                      onClick={() =>
                        markAsRead(
                          groupItem.id,
                          groupItem.landingPage,
                          groupItem.landingPageId
                        )
                      }
                    >
                      <div className="flex justify-between gap-x-4 py-3">
                        <div className="min-w-0 flex-auto">
                          <p
                            className={`${
                              groupItem.isRead === true ? "" : "font-bold"
                            }`}
                          >
                            {groupItem.message}
                          </p>
                          <p className="text-xs mt-2">
                            {moment(groupItem.createdOn).format(
                              "MM/DD/YYYY - h:mm A"
                            )}
                          </p>
                        </div>
                        <div className="shrink-0 sm:flex sm:flex-col sm:items-end">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                            className={`${
                              groupItem.isRead === true
                                ? "text-primary"
                                : "text-secondary"
                            }`}
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m8 12.485l4.243 4.243l8.484-8.485M3 12.485l4.243 4.243m8.485-8.485L12.5 11.5"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="border border-black/10 w-full mt-5" />
              </div>
            )
          )
        ) : (
          <div className="my-4">There are no notifications so far!</div>
        )}
        {currentPage > 1 && isNotificationLoading && (
          <div className="text-center py-2 text-sm text-gray-500">
            Loading messages...
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Menu as="div">
        <Menu.Button
          aria-label="Notifications"
          className="z-0 relative align-middle"
          onClick={() =>
            getNotifications({
              total: Constants?.paginationCount,
              pageNo: 1,
            })
          }
        >
          <BellIcon className="w-6 h-6 text-secondary" />
          {unreadCount > "0" && (
            <span className="bell-circle">{unreadCount}</span>
          )}
        </Menu.Button>
        <Menu.Items className="absolute z-10 bg-white text-black sm:w-1/4 w-11/12 notify-popup h-auto top-14 p-4 border rounded-lg shadow-lg border-black/10 sm:right-20 right-2 origin-top-right">
          <div>
            <div className="items-center flex gap-2 text-black justify-between">
              <p className="text-lg font-semibold">Notifications</p>
              {unreadCount > "0" && (
                <p className="cursor-pointer text-sm" onClick={markAllAsRead}>
                  Mark all as read
                </p>
              )}
            </div>
            <article
              className="notify-height overflow-y-auto"
              ref={notyContainerRef}
              onScroll={handleScroll}
            >
              {(currentPage === 1 && isNotificationLoading) ||
              (isMarkAllLoading && isNotificationLoading) ? (
                <DotsLoader />
              ) : (
                <Menu.Item>
                  <div>{renderNotificationContent()}</div>
                </Menu.Item>
              )}
            </article>
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default Notification;
