import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { LoginAuthentication } from "./LoginAuthentication";
import { Path } from "../../routes/Path";
import { ImagesPath } from "../../assets/images/ImagesPath";
import { LoginSchema } from "../../assets/validation/Schema";
import { SpinningLoader } from "../../components/common/Loader";
import ShowNotification from "../../components/common/ShowNotification";
import { useLazyProfileGetApiQuery } from "../../redux/service/profileApi/ProfileApi";

// Define the FormData type for form values
type FormData = {
  email: string;
  password: string;
};

const Login = () => {
  // React Router's navigate hook
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // State for managing password visibility and button loading
  const [showPassword, setShowPassword] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  // Redux Toolkit query hook for fetching user profile data
  const [getProfile] = useLazyProfileGetApiQuery();

  // Function to handle user data loading after successful login
  const userDataLoad = () => {
    getProfile("").then((res: any) => {
      if (res?.data?.data?.user?.userType === "SUPERADMIN") {
        localStorage.setItem("userDetails", JSON.stringify(res?.data));
        setIsBtnLoading(false);
        navigate(Path.DASHBOARD);
        ShowNotification({
          message: "Successfully Logged In",
          type: "success",
        });
      } else {
        ShowNotification({
          message: "Incorrect Username or Password",
          type: "error",
        });
        handleBtnLoading();
      }
    });
  };

  // useEffect to set document title
  useEffect(() => {
    document.title = "FIDO";
  }, []);

  // React Hook Form setup
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<FormData>({
    resolver: yupResolver(LoginSchema),
  });

  // Function to handle button loading state
  const handleBtnLoading = () => {
    setIsBtnLoading(false);
  };

  // Handler function for login form submission
  const handleLogin = async (data: FormData) => {
    setIsBtnLoading(true);
    LoginAuthentication({
      email: data.email,
      password: data.password,
      // navigate,
      dispatch,
      userDataLoad,
      handleBtnLoading,
    });
  };

  // Function to handle password visibility toggle
  const handleViewPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="fixed h-full w-full left-0 top-0">
      <div className="w-full h-full flex flex-col items-center justify-center bg-neutral">
        <div className="bg-white rounded-xl shadow-md flex sm:p-8 p-4 sm:py-12 py-6 gap-4 flex-col w-11/12 md:w-2/4 lg:w-1/3 xxl:w-1/4 2xl:w-4/12">
          <img
            src={ImagesPath.LOGO}
            alt="FIDO Logo"
            className="mx-auto img-fluid"
          />
          <div>
            <p className="text-2xl font-semibold mb-6" data-testid="titleLogin">
              Login
            </p>
            <form onSubmit={handleSubmit(handleLogin)}>
              {/* Email input */}
              <div className="text-sm font-medium mb-4">
                <label className="text-sm font-medium text-black">Email</label>
                <input
                  aria-label="email"
                  id="email"
                  type="text"
                  placeholder="Enter email address"
                  {...register("email")}
                  className="block w-full rounded-md mt-2 px-2 py-2.5 text-black border border-gray-300 placeholder:text-slate-400 sm:text-sm focus:outline-none focus:border-primary focus:ring-primary"
                />
                {isSubmitted && errors.email && (
                  <p className="text-danger mt-1">{errors?.email?.message}</p>
                )}
              </div>
              {/* Password input */}
              <div className="mb-4">
                <div>
                  <label className="block text-sm font-medium mb-2">
                    Password
                  </label>
                  <div className="relative">
                    <input
                      aria-label="Password"
                      id="Password"
                      data-testid="Password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      {...register("password")}
                      className="py-2.5 px-2 pr-11 block w-full border font-medium border-gray-300 rounded-md text-sm placeholder:text-slate-400 sm:text-sm focus:outline-none focus:border-primary focus:ring-primary"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center cursor-pointer z-20 pr-3">
                      {/* Password visibility toggle icons */}
                      {showPassword ? (
                        <EyeIcon
                          className="w-5 h-5 text-slate-400"
                          onClick={handleViewPassword}
                        />
                      ) : (
                        <EyeSlashIcon
                          className="w-5 h-5 text-slate-400"
                          onClick={handleViewPassword}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {isSubmitted && errors.password && (
                  <p className="text-danger mt-1 font-medium text-sm">
                    {errors?.password?.message}
                  </p>
                )}
              </div>
              {/* Link to navigate to forgot password screen */}
              <Link
                className="flex justify-end text-sm font-medium mb-4 text-primary"
                to={Path.FORGOT_PASSWORD}
              >
                Forgot Password ?
              </Link>
              {/* Submit button */}
              <button
                type="submit"
                className="w-full rounded-md bg-primary px-3 py-2.5 text-base font-semibold leading-6 text-white shadow-sm"
                disabled={isBtnLoading}
              >
                {isBtnLoading ? (
                  <div className="flex justify-center items-center">
                    <span
                      style={{
                        marginRight: "10px",
                      }}
                    >
                      Logging in
                    </span>
                    <SpinningLoader />
                  </div>
                ) : (
                  "Login"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
