import { Navigate, Outlet } from "react-router-dom";

// PrivateRoute component takes an authentication function isAuth as a prop
function PrivateRoute({ isAuth }: { isAuth: () => boolean }) {
  // If the isAuth function returns true, render the child components using Outlet
  // Outlet is a placeholder for child routes to be rendered when the route matches
  return isAuth() ? <Outlet /> : <Navigate to="/" />; // If not authenticated, redirect to the root path "/"
}

export default PrivateRoute; // Export the PrivateRoute component
