import { FC } from "react";
import { ImageAPIProps } from "../../constants/Interfaces";
import { ImagesPath } from "../../assets/images/ImagesPath";
import { useImgApiGetQuery } from "../../redux/service/ImagesApi";

/**
 * Image component handles the display of images, including fetching from an API and rendering placeholders
 * @param {ImageAPIProps} props - Properties passed to the Image component
 * @returns {JSX.Element} - Returns the JSX for the Image component
 */

export const Image: FC<ImageAPIProps> = ({
  name,
  photo,
  isFrom,
  isDetails,
}) => {
  // Fetch image from API using a custom hook
  const { isLoading, data } = useImgApiGetQuery({
    type: "presigedurl",
    folder: "profile_images",
    fileName: photo,
  });

  // Generate initials from the name for broken image placeholder
  const initial = name
    ? name
        .split(/\s{1,2}/)
        .map((word: any, index: number) => {
          if (index < 2) {
            return word.charAt(0);
          }
          return "";
        })
        .join("")
        .toUpperCase()
    : "";

  // Function to generate a random light color
  const getRandomLightColor = () => {
    const minBrightness = 150;
    const randomColor = `rgb(${
      minBrightness + Math.floor(Math.random() * (255 - minBrightness))
    }, ${minBrightness + Math.floor(Math.random() * (255 - minBrightness))}, ${
      minBrightness + Math.floor(Math.random() * (255 - minBrightness))
    })`;
    return randomColor;
  };

  // Function to handle broken image placeholder
  const handleBrokenImage = (event: any) => {
    // Hide the broken image and create a placeholder with initials if not already present
    event.target.style.display = "none";
    const nameInitial = initial || "N/A";

    // Check if a div already exists before creating and appending a new one
    if (!event.target.parentNode.querySelector("div")) {
      const div = document.createElement("div");
      div.textContent = nameInitial;
      div.style.backgroundColor = getRandomLightColor();
      div.style.fontSize = "20px";
      div.style.textAlign = "center";
      div.style.display = "flex";
      div.style.alignItems = "center";
      div.style.justifyContent = "center";
      div.classList.add(
        ...(isFrom === "JobChat"
          ? ["h-12", "w-12", "rounded-full"]
          : ["h-full", "rounded-full"])
      );
      event.target.parentNode.appendChild(div);
    }
  };

  return (
    <>
      {isLoading ? (
        // Render a loading placeholder while image is fetching
        <div className="animate-pulse flex">
          <div
            className={`rounded-full ${
              isDetails
                ? "w-20 h-20"
                : isFrom === "notification"
                ? "h-12 w-12 flex-none"
                : isFrom === "JobChat"
                ? "w-12 h-12"
                : "h-12 w-20"
            } bg-slate-400`}
          />
        </div>
      ) : isFrom === "JobChat" && photo ? (
        // Render image for job chat popup
        <img
          src={data}
          alt=""
          className="rounded-full w-12 h-12"
          onError={handleBrokenImage}
        />
      ) : isFrom === "notification" ? (
        // Render image for notifications
        <img
          src={data}
          alt="img"
          className="rounded-full bg-primary/10 ring-inset ring-primary/10 h-12 w-12 flex-none"
        ></img>
      ) : isFrom === "reviewsCard" ? (
        // Render image for reviews card
        <img
          src={data}
          alt="img"
          className="rounded-full bg-primary/10 ring-inset ring-primary/10 h-10 w-12 flex-none"
        ></img>
      ) : isFrom !== "GuardsChatting" && photo ? (
        // Render image for GuardsChatting
        <img
          src={data}
          alt=""
          className={`rounded-full ${
            isDetails
              ? "h-full rounded-full"
              : isFrom === "GuardsChatting"
              ? "bg-teal border-black-10 h-full"
              : "w-20 h-12"
          } bg-primary/10 ring-inset ring-primary/10`}
          onError={handleBrokenImage}
        />
      ) : isFrom === "GuardsChatting" && photo ? (
        // Render image for other cases
        <img
          src={data}
          alt=""
          className={`rounded-full ${
            isDetails
              ? "h-full rounded-full"
              : isFrom === "GuardsChatting"
              ? "bg-teal border-black-10 h-full"
              : "w-20 h-12"
          } bg-primary/10 ring-inset ring-primary/10`}
          onError={handleBrokenImage}
        />
      ) : photo === null && name !== "-" ? (
        <img
          src={data}
          alt="img"
          className={`${
            isDetails
              ? "h-full"
              : isFrom === "GuardsChatting"
              ? "bg-teal border-black-10 h-full"
              : ""
          } rounded-full`}
          onError={handleBrokenImage}
        />
      ) : (
        // Default image placeholder if photo is not available
        <img
          src={ImagesPath.PROFILE}
          alt="img"
          className={`${
            isDetails
              ? "h-full"
              : isFrom === "GuardsChatting"
              ? "bg-teal border-black-10 h-full"
              : ""
          } rounded-full`}
        />
      )}
    </>
  );
};
