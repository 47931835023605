import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slice/UserSlice";
import { ImagesApi } from "./service/ImagesApi";
import { JobsListApi } from "./service/JobsListApi";
import { JobsApi } from "./service/jobsScreenApi/jobsApi";
import { ProfileApi } from "./service/profileApi/ProfileApi";
import { NotificationsApi } from "./service/notificationApi";
import { GuardsApi } from "./service/guardsScreenApi/GuardsApi";
import { SettingsApi } from "./service/settingsApi/SettingsApi";
import { DashboardApi } from "./service/dashboardScreenApi/dashboardApi";
import { CustomersApi } from "./service/customersScreenApi/CustomersApi";
import { TransactionsApi } from "./service/transactionsScreenApi/TransactionsApi";
import { RatingsAndReviewsApi } from "./service/RatingsAndReviewsScreenApi/RatingsAndReviewsApi";

// Configure the Redux store using configureStore from Redux Toolkit
export const store = configureStore({
  reducer: {
    // Assign reducers and paths for various API services
    [JobsApi.reducerPath]: JobsApi.reducer,
    [GuardsApi.reducerPath]: GuardsApi.reducer,
    [ImagesApi.reducerPath]: ImagesApi.reducer,
    [ProfileApi.reducerPath]: ProfileApi.reducer,
    [SettingsApi.reducerPath]: SettingsApi.reducer,
    [JobsListApi.reducerPath]: JobsListApi.reducer,
    [CustomersApi.reducerPath]: CustomersApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
    [TransactionsApi.reducerPath]: TransactionsApi.reducer,
    [NotificationsApi.reducerPath]: NotificationsApi.reducer,
    [RatingsAndReviewsApi.reducerPath]: RatingsAndReviewsApi.reducer,
    // Assign the userReducer to manage the userState in the store
    userState: userReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      // Concatenate middleware for various API services
      JobsApi.middleware,
      GuardsApi.middleware,
      ImagesApi.middleware,
      ProfileApi.middleware,
      SettingsApi.middleware,
      JobsListApi.middleware,
      CustomersApi.middleware,
      DashboardApi.middleware,
      TransactionsApi.middleware,
      NotificationsApi.middleware,
      RatingsAndReviewsApi.middleware,
    ]),
});

export type AppDispatch = typeof store.dispatch; // Define the type for dispatching actions from the store
export type RootState = ReturnType<typeof store.getState>; // Define the type for the root state of the store
