import { Mutex } from "async-mutex";
import { BaseQueryFn, fetchBaseQuery } from "@reduxjs/toolkit/query";
import { CognitoRefreshToken, CognitoUser } from "amazon-cognito-identity-js";
import { RootState } from "../../Store";
import { saveAwsToken } from "../../slice/UserSlice";
import { Path } from "../../../routes/Path";
import { fetchDomainConfiguration } from "../../../assets/DomainConfiguration";
import UserPool from "../../../pages/login/UserPool";

const fetchDomains = fetchDomainConfiguration();
const baseUrl = fetchDomains?.api_url;

// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token: any | null = (getState() as RootState).userState.token;
    if (token) {
      const idToken =
        typeof token === "string"
          ? JSON.parse(token).idToken.jwtToken
          : token.idToken.jwtToken;
      headers.set("Authorization", idToken);
    }
    return headers;
  },
});
type CustomFetchBaseType = BaseQueryFn<
  any, // The type for the request payload
  unknown, // The type for the response data
  unknown, // The type for the error
  {}, // Additional meta information for the query
  {} // Additional options for the query
>;

const customFetchBase: CustomFetchBaseType = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  return new Promise(async (resolve, reject) => {
    // wait until the mutex is available without locking it
    await mutex.waitForUnlock();

    let result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          const {
            idToken: { payload: { email: Username = "" } = {} } = {},
            refreshToken: { token: RefreshToken = "" } = {},
          } = JSON.parse(localStorage.getItem("token") || "");

          const cognitoUser = new CognitoUser({
            Username,
            Pool: UserPool,
          });

          const token = new CognitoRefreshToken({ RefreshToken });

          cognitoUser.refreshSession(token, async (err, refreshedToken) => {
            if (refreshedToken) {
              api.dispatch(saveAwsToken(JSON.stringify(refreshedToken)));
              result = await baseQuery(args, api, extraOptions);
              resolve(result); // Resolve the promise here
            } else {
              localStorage.clear();
              window.location.href = Path.LOGIN;
              reject(err);
            }
          });
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
      } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
        resolve(result); // Resolve the promise here as well
      }
    } else {
      resolve(result); // Resolve the promise if no error occurred
    }
  });
};

export default customFetchBase;
