import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "../../routes/Path";
import { ImagesPath } from "../../assets/images/ImagesPath";

/**
 * SplashScreen component renders a splash screen.
 * Redirects the user to the dashboard if there's an existing token in local storage after a set timeout.
 * Otherwise, redirects the user to the login page.
 */

const SplashScreen = () => {
  const navigate = useNavigate();
  // Retrieve token from local storage
  const token = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token") || "")
    : null;

  useEffect(() => {
    // Retrieve token from local storage
    setTimeout(() => {
      if (token) {
        navigate(Path.DASHBOARD);
      } else {
        navigate(Path.LOGIN);
      }
    }, 5000);
  }, [token, navigate]);

  return (
    <div className="fixed h-full w-full left-0 top-0">
      <div className="w-full h-full flex flex-col items-center justify-center bg-neutral">
        {/* FIDO logo */}
        <div className="border-image-splash">
          <img alt="fidologo" src={ImagesPath.LOGO_WHITE} />
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
