import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../baseApi/CustomFetchBase";
import { ConfigUrl } from "../../../constants/URL";

export const RatingsAndReviewsApi = createApi({
  reducerPath: "RatingsAndReviewsApi",
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    RatingsReviewsList: builder.mutation({
      query: (params) => ({
        url: ConfigUrl.RATINGS_REVIEWS?.LIST,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useRatingsReviewsListMutation } = RatingsAndReviewsApi;
