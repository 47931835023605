import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "./baseApi/CustomFetchBase";
import { ConfigUrl } from "../../constants/URL";

export const NotificationsApi = createApi({
  reducerPath: "NotificationsApi", // Define the slice name for the reducer
  baseQuery: customFetchBase, // Use customFetchBase as the base for making queries
  endpoints: (builder) => ({
    // Define notificationGetApi endpoint for fetching notifications
    notificationGetApi: builder.query({
      query: ({ total = "total", pageNo = "currentPage" }) => ({
        url: `${ConfigUrl.NOTIFICATION}?page=${pageNo}&pageSize=${total}`, // Construct URL for fetching notifications with pagination
      }),
    }),

    // Define MarkAsReadPatchApi endpoint for marking a specific notification as read
    MarkAsReadPatchApi: builder.query({
      query: ({ notificationId = "notificationId" }) => ({
        url: ConfigUrl.NOTIFICATION, // URL to mark a specific notification as read
        method: "PATCH", // Specify the HTTP method as PATCH
        body: { notificationId }, // Include notificationId in the request body
      }),
    }),

    // Define MarkAllAsReadPatchApi endpoint for marking all notifications as read
    MarkAllAsReadPatchApi: builder.query({
      query: () => ({
        url: ConfigUrl.NOTIFICATION, // URL to mark all notifications as read
        method: "PATCH", // Specify the HTTP method as PATCH
      }),
    }),
  }),
});

export const {
  useLazyNotificationGetApiQuery,
  useLazyMarkAsReadPatchApiQuery,
  useLazyMarkAllAsReadPatchApiQuery,
} = NotificationsApi;
