import { useNavigate } from "react-router-dom";
import { Path } from "../../routes/Path";
import { ImagesPath } from "../../assets/images/ImagesPath";

/**
 * PageNotFound component displays a page indicating that the requested page was not found (404).
 * It provides an option to navigate back to the home page.
 */

const PageNotFound = () => {
  const navigate = useNavigate();

  /**
   * Function to handle navigation to the home page.
   * Clears localStorage and navigates to the login page.
   */
  const handleHomePage = () => {
    localStorage.clear();
    navigate(Path.LOGIN);
  };

  return (
    <div className="fixed h-full w-full left-0 top-0">
      <div className="w-full h-full flex items-center justify-center">
        <div className="flex h-screen flex-col">
          <div className="m-auto text-center">
            <img src={ImagesPath.PAGE_NOT_FOUND} alt="" className="mb-8" />
            <p className="text-3xl font-medium mb-4">Oops! Page Not Found</p>
            <p>
              Sorry, the page you're looking<br></br>for doesn't exist
            </p>
            <button
              className="border border-primary text-primary py-1.5 px-6 rounded-full mt-6 font-weight-semibold"
              onClick={() => handleHomePage()}
            >
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
