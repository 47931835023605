import { useState, useEffect } from "react";

/**
 * NetworkStatus component manages and provides the current online/offline status of the application.
 * @returns {boolean} - Returns a boolean value indicating the network status (true for online, false for offline).
 */

const NetworkStatus = () => {
  // State to manage the online/offline status
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Function to handle online event
    const handleOnline = () => setIsOnline(true);
    // Function to handle offline event
    const handleOffline = () => setIsOnline(false);

    // Adding event listeners for online and offline events
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    // Removing event listeners when the component unmounts
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // Returning the current online/offline status
  return isOnline;
};

export default NetworkStatus;
