import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Path } from "../../routes/Path";
import { LayoutProps } from "../../constants/Interfaces";
import Header from "../../components/common/Header";
import SideMenu from "../../components/common/SideMenu";

/**
 * Layout component defines the overall structure of the application layout,
 * including the header, side menu, and main content area
 * @param {LayoutProps} props - Properties passed to the Layout component
 * @returns {JSX.Element} - Returns the JSX for the Layout component
 */

const Layout: FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const [mobileNav, setMobileNav] = useState(false);

  // Handle path changes to remove specific data from localStorage
  useEffect(() => {
    if (!location.pathname.includes(Path.GUARDS_LIST)) {
      localStorage.removeItem("GuardsList");
    }
    if (!location.pathname.includes(Path.GUARD_TEAM_LIST)) {
      localStorage.removeItem("GuardTeamsList");
    }
    if (!location.pathname.includes(Path.CUSTOMERS_LIST)) {
      localStorage.removeItem("CustomersList");
    }
    if (!location.pathname.includes(Path.JOBS_LIST)) {
      localStorage.removeItem("JobsList");
    }
    if (!location.pathname.includes(Path.TRANSACTIONS_LIST)) {
      localStorage.removeItem("TransactionsList");
    }
  }, [location.pathname]);

  // Toggle mobile navigation
  const onClickMobileNav = () => setMobileNav(!mobileNav);

  return (
    <div>
      <div className="flex w-full h-full fixed left-0 top-0">
        {/* Mobile navigation overlay */}
        <div
          className={`bg-black bg-opacity-50 fixed top-0 left-0 w-full h-full z-10 ${
            mobileNav ? "block" : "hidden"
          }`}
        />
        {/* Side menu component */}
        <SideMenu
          mobileNav={mobileNav}
          onClickMobileNav={() => onClickMobileNav()}
        />
        <main className="flex flex-grow flex-col overflow-hidden">
          {/* Header component */}
          <Header onClickMobileNav={() => onClickMobileNav()} />
          {/* Main content area */}
          <div
            className={`h-full w-full flex-col flex ${
              children?.type?.name === "GuardsChatting" ? "" : "overflow-auto"
            } sm:px-8 py-8 px-4 bg-gray-100 ${
              children?.type?.name === "GuardsChatting"
                ? "overflow-y-hidden"
                : ""
            }`}
          >
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};
export default Layout;
