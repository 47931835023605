// Constants object containing various messages and values
export const Constants = {
  paginationCount: 10, // Pagination count for displaying items
  // Toast messages for various scenarios
  Error: "error need to handle.",
  AddJob: "Enter miles in Radius.",
  IsCodeEmpty: "Code is required.",
  AddMin: "Enter minimum for miles.",
  AddMax: "Enter maximum for miles.",
  TransEdit: "Enter transaction fee.",
  CrimeEdit: "Enter crime index fee.",
  IsEmailEmpty: "Email is mandatory.",
  IsEmailInvalid: "Invalid email format.",
  AddInc: "Enter increment by for radius.",
  IsPasswordEmpty: "Password is mandatory.",
  MinJob: "You can't set 0 as radius in miles.",
  isNewPasswordEmpty: "New Password is required.",
  AddCrime: "Please set 12.5% as crime index fee.",
  MaxCrime: "Crime index fee should be below 20%.",
  AddNotify: "Enter days for notification frequency.",
  IsEmailLength: "Maximum 100 characters are allowed.",
  ErrorUpdate: "An error occurred while updating data.",
  MinLength: "You cannot set minimum miles as beyond 6.",
  MaxLength: "You cannot set maximum miles as beyond 6.",
  AtleastMinValue: "Set atleast 1 as minimum for miles.",
  AtleastMaxValue: "Set atleast 1 as maximum for miles.",
  isConfirmPasswordEmpty: "Confirm Password is required.",
  AboveTrans: "Please set 7.5% as minimum transaction fee.",
  MinGreater: "Minimum miles cannot exceed the maximum miles.",
  IncLength: "You cannot set increment by value as beyond 2.",
  MaxLess: "Maximum miles should be higher than Minimum miles.",
  MinAddAsrb: "Please set $55 as minimum rate for ASRB license.",
  AsrbEdit: "You cannot empty the minimmum rate for ASRB license.",
  isPasswordLength: "Password must be at least 8 characters long.",
  AtleastIncValue: "Set atleast 1 as increment by value for radius.",
  MinAddApost: "Please set $35 as minimum rate for APOST certificate.",
  ApostEdit: "You cannot empty the minimum rate for APOST certificate.",
  MinNotify: "At least enter 1 day as notification frequency as minimum.",
  isPasswordMatch:
    "Both the new password and confirm new password should match.",
  MaxNotify:
    "You're allowed to set only 9 days as notification frequency as maximum.",
  RadiusEqual:
    "Minimum miles of radius and Maximum miles of radius should not be the same.",
};
