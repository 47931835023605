import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import UserPool from "./UserPool";
import { saveAwsToken } from "../../redux/slice/UserSlice";
import { AuthenticationProps } from "../../constants/Interfaces";
import ShowNotification from "../../components/common/ShowNotification";

export const LoginAuthentication = ({
  email,
  password,
  dispatch,
  handleBtnLoading,
  userDataLoad,
}: AuthenticationProps): void => {
  // Prepare authentication data using the provided email and password
  const authenticationData = {
    Username: email,
    Password: password,
  };

  // Create an instance of AuthenticationDetails using the authentication data
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  // Prepare user data for authentication
  const userData = {
    Username: email,
    Pool: UserPool,
  };

  // Create an instance of CognitoUser using the user data
  const cognitoUser = new CognitoUser(userData);

  // Authenticate the user using the provided credentials
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: (data) => {
      // Dispatch an action to save the AWS token to Redux state
      dispatch(saveAwsToken(JSON.stringify(data)));
      // Load user data after successful authentication
      userDataLoad();
    },
    onFailure: (err) => {
      // Handle button loading state
      handleBtnLoading();
      // Show a notification with the error message
      ShowNotification({
        message: err.message,
        type: "error",
      });
    },
  });
};
