import { Path } from "../routes/Path";

// Object mapping page paths to their respective titles
export const PageTitles = {
  [Path.PROFILE]: "Profile",
  [Path.SETTINGS]: "Settings",
  [Path.JOBS_LIST]: "Job List",
  [Path.DASHBOARD]: "Dashboard",
  [Path.GUARDS_LIST]: "Guards List",
  [Path.JOB_DETAILS]: "Job Details",
  [Path.GUARDS_CHATTING]: "Guard Chat",
  [Path.GUARD_REVIEWS]: "Guard Reviews",
  [Path.GUARD_DETAILS]: "Guard Details",
  [Path.CUSTOMERS_LIST]: "Customer List",
  [Path.GUARD_JOBSLIST]: "Guard Jobs List",
  [Path.TRANSACTIONS_LIST]: "Transactions",
  [Path.GUARD_TEAM_LIST]: "Guards Team List",
  [Path.GUARD_TEAM_DETAILS]: "Guard Details",
  [Path.CUSTOMER_DETAILS]: "Customer Details",
  [Path.RATINGSANDREVIEWS]: "Ratings & Reviews",
  [Path.GUARD_TEAM_JOBSLIST]: "Guard Jobs List",
  [Path.CUSTOMER_JOBSLIST]: "Customer Jobs List",
  [Path.TRANSACTION_DETAILS]: "Transaction Details",
};
