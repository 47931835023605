const GuardDetails = "/guards-list/guard-details";
const CustomerDetails = "/customers-list/customer-details";
const GuardTeamList = "/guards-list/guards-team-list";
const GuardTeamDetails = `${GuardTeamList}/guard-details`;

export const Path = {
  SPLASH: "/",
  LOGIN: "/login",
  PROFILE: "/profile",
  SETTINGS: "/settings",
  JOBS_LIST: "/jobs-list",
  DASHBOARD: "/dashboard",
  GUARDS_LIST: "/guards-list",
  CUSTOMERS_LIST: "/customers-list",
  TRANSACTIONS_LIST: "/transactions",
  FORGOT_PASSWORD: "/forgot-password",
  JOB_DETAILS: "/jobs-list/job-details",
  CONFIRM_PASSWORD: "/confirm-password",
  RATINGSANDREVIEWS: "/ratings&reviews",
  TRANSACTION_DETAILS: "/transactions/transaction-details",
  GUARD_DETAILS: GuardDetails,
  GUARD_TEAM_LIST: GuardTeamList,
  CUSTOMER_DETAILS: `${CustomerDetails}`,
  GUARD_REVIEWS: `${GuardDetails}/guard-reviews`,
  GUARD_JOBSLIST: `${GuardDetails}/guard-jobslist`,
  GUARDS_CHATTING: `${GuardDetails}/guard-chatting`,
  GUARD_TEAM_DETAILS: `${GuardTeamDetails}`,
  CUSTOMER_JOBSLIST: `${CustomerDetails}/customer-jobslist`,
  GUARD_TEAM_JOBSLIST: `${GuardTeamDetails}/guard-jobslist`,
};
