import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBase from "../baseApi/CustomFetchBase";
import { ConfigUrl } from "../../../constants/URL";

export const ProfileApi = createApi({
  reducerPath: "ProfileApi", // Define the slice name for the reducer
  baseQuery: customFetchBase, // Use customFetchBase as the base for making queries
  endpoints: (builder) => ({
    // Endpoint: profileGetApi
    profileGetApi: builder.query({
      query: () => ({
        url: ConfigUrl?.PROFILE?.PROFILE, // Define the URL for fetching profile data
      }),
    }),
  }),
});

export const { useLazyProfileGetApiQuery } = ProfileApi;
