import { FC, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import {
  StarIcon,
  UserIcon,
  BanknotesIcon,
  Cog8ToothIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import BriefcaseIconOutlined from "@heroicons/react/24/outline/BriefcaseIcon";
import { Path } from "../../routes/Path";
import { PageTitles } from "../../constants/PageTitles";
import { SideMenuProps } from "../../constants/Interfaces";
import { ImagesPath } from "../../assets/images/ImagesPath";

/**
 * SideMenu component that renders the side navigation menu.
 * Displays links to various sections with dynamic active styles based on the current pathname.
 * Updates the document title according to the current page.
 */

const SideMenu: FC<SideMenuProps> = ({ mobileNav, onClickMobileNav }) => {
  const { pathname } = useLocation();

  // Update document title based on the current page pathname
  useEffect(
    () => {
      document.title = `FIDO || ${PageTitles[pathname] || "Unknown Page"}`;
    }, //eslint-disable-next-line
    [pathname]
  );

  // Function to determine active link styles based on the current pathname
  const isActiveLink = (targetPaths: string | string[]) => {
    const paths = Array.isArray(targetPaths) ? targetPaths : [targetPaths];
    return paths.some((path) => pathname.startsWith(path))
      ? "items-center text-white p-4 font-normal flex gap-4 bg-white bg-opacity-10"
      : "items-center text-white p-4 font-normal flex gap-4";
  };

  return (
    <div>
      <aside
        className={`w-64 bg-neutral transition-all flex flex-col gap-6 xl:relative fixed h-full xl:left-0 z-20 ${
          mobileNav ? "left-0" : "-left-96"
        }`}
      >
        {/* Sidebar content */}
        <div className="p-4 flex justify-center relative">
          <img src={ImagesPath.LOGO_WHITE} alt="FIDO" className="w-1/2" />
          <button
            className="absolute -right-8 block xl:hidden"
            onClick={() => {
              onClickMobileNav();
            }}
          >
            <XMarkIcon className="w-6 h-6 text-white" />
          </button>
        </div>
        {/* Sidebar navigation */}
        <nav className="flex flex-col">
          {/* Links to different sections with conditional active styles */}
          {/* Dashboard */}
          <Link className={isActiveLink(Path.DASHBOARD)} to={Path.DASHBOARD}>
            <Squares2X2Icon className="w-6 h-6" />
            Dashboard
          </Link>
          {/* Guards List */}
          <Link
            className={isActiveLink([
              Path.GUARDS_LIST,
              Path.GUARD_DETAILS,
              Path.GUARD_REVIEWS,
              Path.GUARD_JOBSLIST,
              Path.GUARD_TEAM_LIST,
              Path.GUARD_TEAM_DETAILS,
              Path.GUARD_TEAM_JOBSLIST,
            ])}
            to={Path.GUARDS_LIST}
          >
            <Icon icon="mingcute:user-security-line" width="24" />
            Guards List
          </Link>
          {/* Customer List */}
          <Link
            className={isActiveLink([
              Path.CUSTOMERS_LIST,
              Path.CUSTOMER_DETAILS,
              Path.CUSTOMER_JOBSLIST,
            ])}
            to={Path.CUSTOMERS_LIST}
          >
            <UserIcon className="w-6 h-6" />
            Customer List
          </Link>
          {/* Job List */}
          <Link
            className={isActiveLink(Path.JOBS_LIST || Path.JOB_DETAILS)}
            to={Path.JOBS_LIST}
          >
            <BriefcaseIconOutlined className="w-6 h-6" />
            Job List
          </Link>
          {/* Transactions */}
          <Link
            className={isActiveLink(
              Path.TRANSACTIONS_LIST || Path.TRANSACTION_DETAILS
            )}
            to={Path.TRANSACTIONS_LIST}
          >
            <BanknotesIcon className="w-6 h-6" />
            Transactions
          </Link>
          {/* Ratings & Reviews */}
          <Link
            className={isActiveLink(Path.RATINGSANDREVIEWS)}
            to={Path.RATINGSANDREVIEWS}
          >
            <StarIcon className="w-6 h-6" />
            Ratings & Reviews
          </Link>
          {/* Settings */}
          <Link className={isActiveLink(Path.SETTINGS)} to={Path.SETTINGS}>
            <Cog8ToothIcon className="w-6 h-6" />
            Settings
          </Link>
        </nav>
      </aside>
    </div>
  );
};

export default SideMenu;
