import { useNavigate } from "react-router-dom";
import { Path } from "../../routes/Path";
import { ImagesPath } from "../../assets/images/ImagesPath";

/**
 * Component to render the server down or server update page.
 * Provides information and options for users when the server is inaccessible or undergoing maintenance.
 */

const ServerDownPage = () => {
  const navigate = useNavigate();

  // Function to handle navigating to home page
  const handleHomePage = () => {
    localStorage.clear();
    navigate(Path.LOGIN);
  };

  return (
    <div className="fixed h-full w-full left-0 top-0">
      <div className="w-full h-full flex items-center justify-center">
        <div className="flex h-screen flex-col">
          <div className="m-auto text-center">
            <img src={ImagesPath.SERVER_DOWN} alt="" className="m-auto mb-8" />
            <p className="text-3xl font-medium mb-4">
              Server down/ Server update
            </p>
            <p>
              Something went wrong...<br></br>Please try again later
            </p>
            {window.location.pathname !== Path.LOGIN ? (
              <button
                className="border border-primary text-primary py-1.5 px-6 rounded-full mt-6 font-weight-semibold"
                onClick={() => handleHomePage()}
              >
                Back to home
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerDownPage;
